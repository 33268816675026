<!-- @format -->

<template>
  <div class="login-div">
    <div class="inner-div">
      <img src="../assets/logo.png" class="logo" alt="Logo" />
      <label class="tocenter"><u>Login</u></label>

      <div class="stepper">
        <span class="errmsg" :class="errclass" v-if="err">{{ errmsg }}</span>
        <div class="step" v-if="stepnumber == 1">
          <label for="class">Class:<MandatoryIcon /></label>
          <select
            class="form-select"
            v-model="classobj"
            @change="getSections()">
            <option :value="{}">--Select--</option>
            <option v-for="rw in classList" :key="rw.classid" :value="rw">
              {{ rw.cldes }}
            </option>
          </select>
          <label for="class">Section:<MandatoryIcon /></label>
          <select class="form-select" v-model="sec">
            <option value="">--Select--</option>

            <option v-for="rw in sectionList" :key="rw.sec" :value="rw.sec">
              {{ rw.sec }}
            </option>
          </select>
          <label for="class">Period:<MandatoryIcon /></label>

          <select class="form-select" v-model="prd">
            <option value="">--Select--</option>
            <option v-for="rw in periodList" :key="rw" :value="rw">
              {{ rw }}
            </option>
          </select>

          <label for="class">Subject:<MandatoryIcon /></label>
          <select class="form-select" v-model="subjectobj">
            <option :value="{}">--Select--</option>
            <option v-for="rw in subjectList" :key="rw" :value="rw">
              {{ rw.sname }}
            </option>
          </select>
          <button class="btn btn-primary" type="button" @click="step('next')">
            Next
          </button>
        </div>
        <div class="step" v-if="stepnumber == 2">
          <span class="classdet"
            >Class:{{ cldes }} {{ sec }}
            <span class="classdet">Period:{{ prd }}</span>
            <span class="classdet">Subject:{{ subjectobj.sname }}</span>
          </span>
          <label for="uname">User Name:</label>
          <input
            type="text"
            name="uname"
            ref="uname"
            class="form-control"
            v-model="uname"
            maxlength="20"
            autocomplete="off"
            autofocus
            v-on:keydown.enter="login()" />
          <label for="upass">Password:</label>
          <input
            type="password"
            name="upass"
            ref="upass"
            class="form-control"
            v-model="upass"
            maxlength="20"
            autocomplete="off"
            v-on:keydown.enter="login()" />
          <button class="btn btn-success" type="button" @click="login()">
            Login
          </button>

          <button class="btn btn-danger" type="button" @click="step('prev')">
            Previous
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
export default {
  name: "LoginForm",
  emits: ["login-btn"],
  data() {
    return {
      uname: "",
      upass: "",
      err: false,
      errmsg: "",
      errclass: "",
      classList: [],
      sectionList: [],
      subjectList: [],
      periodList: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10],
      stepnumber: 1,
      classobj: {},
      cldes: "",
      class: "",
      class1: "",
      sec: "",
      prd: "",
      subjectobj: {},
    };
  },
  methods: {
    async getClasses() {
      await axios
        .post("classes.php", {
          optn: "Classes",
        })
        .then((resp) => {
          if (resp.data.Error == false) {
            this.classList = resp.data.List;
          } else {
            console.log("Error Occured!");
            console.log(resp);
          }
        });
    },

    async getSubjects() {
      await axios
        .post("subjects.php", {
          optn: "Subjects",
        })
        .then((resp) => {
          if (resp.data.Error == false) {
            this.subjectList = resp.data.List;
          } else {
            console.log("Error Occured!");
            console.log(resp);
          }
        });
    },
    async getSections() {
      this.class = this.classobj.class;
      this.class1 = this.classobj.class1;
      this.cldes = this.classobj.cldes;
      await axios
        .post("classes.php", {
          optn: "Classec",
          class: this.class,
          class1: this.class1,
        })
        .then((resp) => {
          if (resp.data.Error == false) {
            this.sectionList = resp.data.List;
          } else {
            console.log("Error Occured!");
            console.log(resp);
          }
        });
    },
    async login() {
      this.uname = this.uname.toUpperCase();
      this.upass = this.upass.toUpperCase();
      if (this.uname != "" && this.upass != "") {
        this.err = true;
        this.errclass = "warnmsg";
        this.errmsg = "Authenticating...";
        var loginsts = {
          isAuth: false,
          Uname: this.uname,
          Uid: "",
          StoreFlg: "",
          DepId: "",
          DepDes: "",
        };

        var response;
        await axios
          .post("users.php", {
            optn: "checkuser",
            uname: this.uname,
            upass: this.upass,
          })
          .then(function (resp) {
            response = resp.data;
          });

        if (response.Errorsts === false) {
          var myobj = {
            class: this.class,
            class1: this.class1,
            cldes: this.cldes,
            sec: this.sec,
            prd: this.prd,
            scode: this.subjectobj.scode,
            sname: this.subjectobj.sname,
          };
          localStorage.setItem("ClassObj", JSON.stringify(myobj));

          this.$store.state.classObj = myobj;

          if (response.List.length > 0) {
            await axios
              .post("loginlog.php", {
                optn: "loginlog",
                class: this.class,
                class1: this.class1,
                cldes: this.cldes,
                sec: this.sec,
                prd: this.prd,
                uid: response.List[0].uid,
                ename: response.List[0].ename,
              })
              .then(() => {
                // console.log("LoginLog:", r);
              });

            this.errclass = "donemsg";
            this.errmsg = "Welcome!" + this.uname;
            var control = response.control[0];
            loginsts.isAuth = true;
            loginsts.Uid = response.List[0].uid;
            loginsts.Uname = response.List[0].uname;
            localStorage.setItem("Uname", loginsts.Uname);
            localStorage.setItem("Uid", loginsts.Uid);
            localStorage.setItem("Auth", loginsts.isAuth);
            localStorage.setItem("School", control.school);
            this.$store.state.schoolName = control.school;
            this.$store.state.isAuth = true;

            if (response.List[0].catg == "Y") {
              this.$store.state.isTeacher = true;
              localStorage.setItem("IsTeacher", true);
              localStorage.setItem("ShowDash", false);
              localStorage.setItem("Ename", response.List[0].ename);
              this.$store.state.userName = response.List[0].ename;
              this.$store.state.eName = response.List[0].ename;
            } else {
              this.$store.state.isTeacher = false;
              this.$store.state.userName = loginsts.Uname;
            }

            this.$store.state.userId = loginsts.Uid;
            this.$router.push("/home");
          } else {
            this.errclass = "dangermsg";
            this.errmsg = "Invalid Username/Password!";
          }
        } else {
          this.errclass = "dangermsg";
          this.errmsg = "Unable to Login! Something Went Wrong!";
          console.log(response.ErrorMsg);
        }
      } else {
        this.err = true;
        this.errmsg = "Please Fill User Name/Password!";
        this.errclass = "dangermsg";
      }
    },
    isEmpty() {
      console.log("Subject Object", this.subjectobj.scode);
      if (
        this.class == "" ||
        this.sec == "" ||
        this.prd == "" ||
        this.subjectobj.scode == undefined
      ) {
        return true;
      } else {
        return false;
      }
    },
    step(xstep) {
      if (xstep == "next") {
        if (!this.isEmpty()) {
          this.err = false;
          this.stepnumber++;
        } else {
          this.err = true;
          this.errmsg = "Please Fill Mandatory Fields!";
          this.errclass = "dangermsg";
        }
        // this.$refs.uname.focus();
      } else {
        this.stepnumber--;
      }
      this.uname = "";
      this.upass = "";
    },
  },
  async mounted() {
    await this.getClasses();
    await this.getSubjects();
  },
};
</script>

<style scoped>
.login-div {
  position: relative;
  margin: 0px auto !important;
  left: 0;
  right: 0;
  top: 50px;
  width: 350px;
  border-radius: 4px;
  padding: 8px;
  padding-top: 20px;
  padding-bottom: 40px;
  text-align: left;
  box-shadow: 2px 6px 12px 1px #323232;
  background-color: white;
}

.inner-div {
  padding: 5px;
}

.logo {
  position: relative;
  display: block;
  margin: 0px auto !important;
  width: 80px;
}

label {
  position: relative;
  font-weight: bold;
  margin-bottom: 5px;
  margin-top: 5px;
  display: block;
}

.btn {
  display: block;
  width: 100%;
  margin-top: 15px;
  margin-bottom: 15px;
}

.errmsg {
  position: relative;
  display: block;
  text-align: center;
  padding: 3px;
  font-size: 14px;
  font-weight: bold;
  border-radius: 5px;
}
.dangermsg {
  background-color: #f12b08f2;
  color: white;
}
.warnmsg {
  background-color: #ebca12;
  color: white;
}
.donemsg {
  background-color: #05bb23;
  color: white;
}

.classdet {
  position: relative;
  display: block;
  text-align: center;
  font-weight: bold;
}
</style>
