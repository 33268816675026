<!-- @format -->

<template>
  <div>
    <div class="mynavbr">
      <div class="title-div">
        <div class="logo-div">
          <!-- <div class="snack-bar" @click="toggleSideBar()">
            <span class="fas fa-th"></span>
          </div> -->
          <img src="../assets/logo.png" class="topbarlogo" />
        </div>

        &nbsp;&nbsp;<router-link to="/home" class="title">{{
          $store.state.schoolName
        }}</router-link>
      </div>
      <div class="logout-div">
        <span class="logout" @click="toggleLogout"
          ><span class="username">({{ userName }})</span> Logout</span
        >
      </div>
      <span class="classdet">
        Class: {{ $store.state.classObj.cldes }}
        {{ $store.state.classObj.sec }} Period:
        {{ $store.state.classObj.prd }} Subject:
        {{ $store.state.classObj.sname }}
      </span>
    </div>
    <logout-box
      v-show="logoutbox"
      @cancel="toggleLogout"
      @logout="logoutBtn"></logout-box>
  </div>
</template>

<script>
import LogoutBox from "./LogoutBox.vue";
import axios from "axios";
export default {
  emits: ["toggleBar"],
  components: {
    LogoutBox,
  },
  data() {
    return {
      logoutbox: false,
      userName: this.$store.state.userName,
    };
  },
  methods: {
    toggleLogout() {
      this.logoutbox = !this.logoutbox;
    },
    async logoutBtn() {
      await axios
        .post("loginlog.php", {
          optn: "logoutlog",
          uid: this.$store.state.userId,
        })
        .then(() => {
          // console.log("LogoutLog:", r);
        });
      localStorage.clear();
      this.$router.push("/");
      this.$store.state.isAuth = false;
    },
    toggleSideBar() {
      this.$emit("toggleBar");
    },
  },
};
</script>

<style scoped>
.mynavbr {
  position: fixed;
  width: 100%;
  top: 0px;
  display: block;
  box-shadow: 0px 1px 8px 2px lightgray;
  text-align: left;
  padding: 10px;
  z-index: 999;
  background-color: white;
}

.mynavbr .title-div,
.logout-div {
  /* width: calc(50% - 20px); */
  display: inline-block;
  position: relative;
  /* border: 1px solid lightgray; */
}
.title-div {
  text-align: left;
  left: 5px;
}
.title {
  text-decoration: none;
  color: slategray;
  font-size: 22px;
  font-weight: 600;
}
.logout-div {
  float: right;
  text-align: right;
  right: 10px;
  padding-top: 10px;
}
.logout {
  font-size: 18px;
  font-weight: 800;
  color: slategray;
  cursor: pointer;
}
.logout:hover {
  color: #2c3e50;
}

.username {
  position: relative;
  color: #2c3e50;
  margin-right: 5px;
}

.fa-bars {
  display: none;
}
.topbarlogo {
  height: 65px;
}
.mynavbr .logo-div {
  position: relative;
  display: inline-block;
}

.classdet {
  position: relative;
  display: block;
  widows: 100%;
  margin-top: 10px;
  margin-bottom: 10px;
  font-weight: bold;
  text-align: center;
}

@media only screen and (max-width: 1000px) {
  .snack-bar {
    position: relative;
    display: inline-block;
    cursor: pointer;
    margin-right: 5px;
  }
  .fas .fa-bars {
    color: black;
  }
  .fas .fa-bars :hover {
    color: salmon;
    background-color: rgb(221, 45, 45);
    border-radius: 25px;
  }

  .title {
    font-size: 18x;
    font-weight: 600;
  }
  .logout {
    font-size: 16px;
    font-weight: 600;
  }
  .topbarlogo {
    height: 45px;
  }
}

@media only screen and (max-width: 600px) {
  .title {
    font-size: 12px;
    font-weight: bold;
  }
}
</style>
