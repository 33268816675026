import { createStore } from "vuex";

export default createStore({
  state: {
    isTeacher:
      localStorage.getItem("IsTeacher") == null
        ? false
        : Boolean(localStorage.getItem("IsTeacher")),
    userName:
      localStorage.getItem("Uname") == null
        ? ""
        : localStorage.getItem("Uname"),
    eName:
      localStorage.getItem("Ename") == null
        ? ""
        : localStorage.getItem("Ename"),
    isAuth:
      localStorage.getItem("Auth") == null
        ? false
        : Boolean(localStorage.getItem("Auth")),
    schoolName:
      localStorage.getItem("School") == null
        ? ""
        : localStorage.getItem("School"),
    userId:
      localStorage.getItem("Uid") == null ? "" : localStorage.getItem("Uid"),
    classObj:
      localStorage.getItem("ClassObj") == null
        ? {}
        : JSON.parse(localStorage.getItem("ClassObj")),
    picPath: "https://holyheartamritsar.in/superkids/photos/",
  },
  getters: {},
  mutations: {},
  actions: {},
  modules: {},
});
