<!-- @format -->

<template>
  <div class="login-page">
    <login-form></login-form>
  </div>
</template>
<script>
import LoginForm from "../components/LoginFrom.vue";
export default {
  components: {
    LoginForm,
  },
};
</script>
<style scoped>
.login-page {
  position: relative;
  width: 100%;
  height: 100vh;
  background-color: rgb(42, 193, 253);
}
</style>
