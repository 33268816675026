import { createRouter, createWebHashHistory } from "vue-router";
import LoginPage from "../views/LoginPage.vue";
import store from "../store/index.js";

const routes = [
  {
    path: "/",
    name: "login",
    component: LoginPage,
  },
  {
    path: "/home",
    name: "home",
    component: () => import("../views/HomeView.vue"),
  },
  {
    path: "/sendmessage",
    name: "message",
    component: () => import("../views/MessageAdd.vue"),
  },
];

const router = createRouter({
  history: createWebHashHistory(),
  routes,
});

router.beforeEach((to, from, next) => {
  if (to.path == "/") {
    if (store.state.isAuth == true) {
      next("/home");
    } else {
      next();
    }
  } else {
    if (store.state.isAuth == false) {
      next("/");
    } else {
      next();
    }
  }
});

export default router;
