import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import axios from "axios";
import MandatoryPage from "./components/MandatoryPage.vue";
import CardLoader from "./components/CardLoader.vue";
// axios.defaults.baseURL =
//   "http://192.168.29.51:8180/HolyHeart/presi/teacherapp/src/php/";

// axios.defaults.baseURL = "http://hpt.holyheartamritsar.in/php/"; //Presidency
// axios.defaults.baseURL = "http://gtt.holyheartamritsar.in/php/"; //Gt Road
axios.defaults.baseURL = "http://skt.holyheartamritsar.in/php/"; //Superkids

var app = createApp(App);
app.use(store);
app.use(router);
app.component("MandatoryIcon", MandatoryPage);
app.component("CardLoader", CardLoader);
app.mount("#app");
